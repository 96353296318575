/* --------- Tailwind imports --------- */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* --------- Clear search input styling --------- */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url("../public/icons/close.svg") no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

/* --------- Ellipses animation --------- */

.ellipses:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1200ms infinite;
  animation: ellipsis steps(4, end) 1200ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

/* --------- Auth container --------- */

.auth-container {
  height: 616px;
  width: 364px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 4px #00000029;
  border-radius: 8px;
}

/* --------- Root styles --------- */

:root {
  /* Colors: */
  --white: #ffffff;
  --medium-gray: #7f7f7f;
  --light-gray: #f1f1f1;
  --red-6: #a41c4e;
  --red-1: #fde2dd;
  --primary-action: #000000;
  --green-1: #cbf4c9;
  --green-6: #0e6245;
  --orange-1: #fee3c0;
  --orange-6: #9e2f28;
  --unnamed-color-6c63ff: #6c63ff;
}

/* --------- Typography --------- */

@font-face {
  font-family: "Suisse Intl";
  src: url(./fonts/suisse/SuisseIntl-Regular-WebXL.woff2) format("woff2"),
    url(./fonts/suisse/SuisseIntl-Regular-WebXL.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Intl";
  src: url(./fonts/suisse/SuisseIntl-Medium-WebXL.woff2) format("woff2"),
    url(./fonts/suisse/SuisseIntl-Medium-WebXL.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Intl";
  src: url(./fonts/suisse/SuisseIntl-SemiBold-WebXL.woff2) format("woff2"),
    url(./fonts/suisse/SuisseIntl-SemiBold-WebXL.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: "Suisse Intl", "Helvetica", "Arial", sans-serif;
  font-feature-settings: "salt";
  -o-font-feature-settings: "salt";
  -moz-font-feature-settings: "salt";
  -webkit-font-feature-settings: "salt";
}
